.main_landingPage__modal-container {
  max-width: 500px;
  width: 100%;
  margin: auto 10px;
  display: flex;
  flex-direction: column;
}


.main_landingPage__modal-container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


div.form_inputs {
  margin: 10px auto;
  width: 100%;
}

#transition-modal-caption {
  font-size: 14px;
  text-align: center;
  margin: 10px auto;
  margin-top: 0px;
}

#transition-modal-title{
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}

#transition-modal-logo {
  width: 100px;
  margin: auto;
}

#transition-modal-description {
  text-align: center;
}

#transition-modal-description-highlight {
  text-align: center;
  font-weight: bold;
}

button.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background: linear-gradient(to right, #512da8, #711e72);
  opacity: 0.9;
}